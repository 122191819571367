import { PropsWithChildren } from 'react';
import { Flex, HStack, useColorModeValue } from '@chakra-ui/react';

import { useIsMobile } from '@bq/components/use-is-mobile';

import { EditBreadcrumbs } from '../Breadcrumbs';
import { CreateBreadcrumbs } from '../Breadcrumbs/CreateBreadcrumbs';
import { useTicketMutationContext } from '../FormWrapper/TicketMutationContext';

export const TicketBreadcrumbsHeader = ({ children }: PropsWithChildren) => {
  const { ...modeProps } = useTicketMutationContext();

  const headerColor = useColorModeValue('#e0e0e0', '#353535');

  const isMobile = useIsMobile();

  return (
    <Flex
      flex="0 1 auto"
      flexDirection={{ base: 'column', lg: 'row' }}
      px={{ base: 'none', lg: 6 }}
      py={2}
      w="full"
      alignItems="center"
      bg={headerColor}
    >
      <HStack
        w={isMobile ? '100%' : 'auto'}
        flex="0 1 auto"
        align="center"
        justify="center"
        py={{ base: 2, lg: 0 }}
        fontSize={{ base: 'xl', lg: 'sm' }}
        fontWeight={{ base: 'bold', lg: 'normal' }}
      >
        {modeProps.mode === 'edit' ? (
          <EditBreadcrumbs />
        ) : (
          <CreateBreadcrumbs />
        )}
      </HStack>

      {children}
    </Flex>
  );
};
