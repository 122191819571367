import { FilterExpression } from '@bq/components/FilterBar';
import { GetListingParams } from 'BootQuery/Assets/js/globalTypes';

import { getTickets } from '../Api';
import { Ticket } from '../types';
import { conditionsFromSort, reverseSorts } from './util';

export type AdjacentTicketParams = Pick<
  GetListingParams,
  'sort' | 'filters' | 'fields'
>;

export async function getPreviousTicket(
  currentTicket: Ticket,
  params: AdjacentTicketParams
): Promise<Ticket | null> {
  const sort = normalizeSort(params.sort);

  const { data: tickets } = await getTickets({
    ...params,
    limit: 1,
    sort: reverseSorts(sort),
    filters: mergeFilters(
      conditionsFromSort(currentTicket, sort, 'prev'),
      params.filters
    ),
    withCount: false,
  });

  return tickets.data[0] ?? null;
}

export async function getNextTicket(
  currentTicket: Ticket,
  params: AdjacentTicketParams
): Promise<Ticket | null> {
  const sort = normalizeSort(params.sort);

  const { data: tickets } = await getTickets({
    ...params,
    limit: 1,
    filters: mergeFilters(
      conditionsFromSort(currentTicket, sort, 'next'),
      params.filters
    ),
    withCount: false,
  });

  return tickets.data[0] ?? null;
}

function mergeFilters(
  conditions: FilterExpression,
  otherFilters?: FilterExpression | FilterExpression[]
): FilterExpression | FilterExpression[] {
  if (!otherFilters) {
    return conditions;
  }

  if (Array.isArray(otherFilters)) {
    if (otherFilters.length === 0) {
      return conditions;
    }

    return { $and: [...otherFilters, conditions] };
  }

  return { $and: [otherFilters, conditions] };
}

function normalizeSort(sort: string | string[] | undefined): string[] {
  if (!sort) {
    return [];
  }

  if (typeof sort === 'string') {
    return [sort];
  }

  return sort;
}
