import {
  Button,
  Center,
  Heading,
  HStack,
  ModalBody,
  ModalCloseButton,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { ImArrowRight2 } from 'react-icons/im';

import { Breadcrumb, Breadcrumbs } from '@bq/components/Breadcrumbs';
import { Layout, LayoutBody, LayoutHeader } from '@bq/components/Layout';

import { useTicketingContext } from '../TicketingContext/use-ticketing-context';
import { TicketModalTypeSelectData } from './types';
import { useTicketModal } from './use-ticket-modal';

export const TicketModalSelectType = ({
  createParams,
}: TicketModalTypeSelectData) => {
  const { types } = useTicketingContext();

  const { t } = useTranslation('Ticketing');

  const { createTicketFromTypeSelect } = useTicketModal();
  const nameColor = useColorModeValue('black', 'white');
  const bodyColor = useColorModeValue('#F5F5F5', '#393939');
  const hoverColor = useColorModeValue('gray.200', 'brand.mediumGray');

  return (
    <ModalBody m={0} p={0} w="full">
      <Center w="full">
        <Layout w="full">
          <LayoutHeader borderTopRadius="5px" justifyContent="flex-end">
            <Heading p={0} m={0} size="md" flex="1 1 auto">
              {t('Ticketing:ticket_types')}
            </Heading>
            <Breadcrumbs>
              <Breadcrumb to="/ticketing/list">
                {t('Ticketing:ticketing_title')}
              </Breadcrumb>
              <Breadcrumb to="/ticketing/create">
                {t('Ticketing:ticket_types')}
              </Breadcrumb>
            </Breadcrumbs>
            <ModalCloseButton pos="static" />
          </LayoutHeader>
          <LayoutBody px={0} py={0} height="full">
            <VStack spacing={3} alignItems="left" px={8} py={2}>
              {types.map((type) => (
                <HStack
                  _hover={{ bg: hoverColor }}
                  boxShadow=" rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;"
                  borderRadius="5px"
                  p={5}
                  h={7}
                  bg={bodyColor}
                  key={type.ID}
                >
                  <Button
                    bg="transparent"
                    _hover={{ textDecoration: 'none', color: nameColor }}
                    color={nameColor}
                    display="block"
                    width="full"
                    onClick={() => {
                      createTicketFromTypeSelect(type.slug, createParams);
                    }}
                  >
                    {type.name}
                  </Button>
                  <ImArrowRight2 />
                </HStack>
              ))}
            </VStack>
          </LayoutBody>
        </Layout>
      </Center>
    </ModalBody>
  );
};
