import {
  PropsWithChildren,
  ReactElement,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import { ConditionalWrapper } from '@bq/components/ConditionalWrapper';
import { uniqueId } from '@bq/components/Overviews/OverviewEditor/util';

import { TicketingContext, TicketingProvider } from '../TicketingContext';
import { CreateParams } from '../types';
import { TicketModal } from './TicketModal';
import { TicketModalContext } from './TicketModalContext';
import { CreateModalParams, TicketModalState } from './types';

export const TicketModalProvider = ({
  children,
}: PropsWithChildren): ReactElement => {
  const hasContext = useContext(TicketingContext) !== null;
  const [modalState, setModalState] = useState<TicketModalState[]>([]);

  const createTicket = useCallback((params: CreateModalParams = {}) => {
    const createParams = params.createParams ?? {};
    const ID = uniqueId();

    if (!params.typeSlug) {
      setModalState((prev) => [
        ...prev,
        { ID, mode: 'typeSelect', createParams },
      ]);
    } else {
      setModalState((prev) => [
        ...prev,
        {
          ID,
          mode: 'create',
          typeSlug: params.typeSlug as string,
          createParams,
          submitCallback: params.submitCallback,
          apiCallParams: params.apiCallParams,
          parentID: params.parentID,
        },
      ]);
    }
  }, []);

  const editTicket = useCallback(
    (ticketID: number, params: CreateModalParams = {}) => {
      const ID = uniqueId();

      setModalState((prev) => [
        ...prev,
        {
          ID,
          mode: 'edit',
          ticketID,
          submitCallback: params.submitCallback,
          apiCallParams: params.apiCallParams,
          parentID: params.parentID,
        },
      ]);
    },
    []
  );

  const closeModal = useCallback(
    (ID: string) => {
      setModalState((prev) => prev.filter((item) => item.ID !== ID));
    },
    [setModalState]
  );

  const createTicketFromTypeSelect = useCallback(
    (selectedType: string, params: CreateParams = {}) => {
      const ID = uniqueId();

      setModalState((prev) => [
        ...prev,
        {
          ID,
          mode: 'create',
          typeSlug: selectedType,
          createParams: params,
        },
      ]);
    },
    []
  );

  const ctxValue = useMemo(
    () => ({
      createTicket,
      editTicket,
      createTicketFromTypeSelect,
      closeModal,
    }),
    [createTicket, editTicket, createTicketFromTypeSelect, closeModal]
  );

  return (
    <ConditionalWrapper
      shouldWrap={!hasContext}
      render={(children) => <TicketingProvider>{children}</TicketingProvider>}
    >
      <TicketModalContext.Provider value={ctxValue}>
        {children}
        {modalState.map((item) => (
          <TicketModal onClose={closeModal} state={item} key={item.ID} />
        ))}
      </TicketModalContext.Provider>
    </ConditionalWrapper>
  );
};
