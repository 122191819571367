import {
  Button,
  ButtonGroup,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaChevronDown, FaUndo } from 'react-icons/fa';

import { useTicketMutationContext } from '../TicketingForm/FormWrapper/TicketMutationContext';
import { TicketForm } from '../types';

export const TicketingSaveActions = () => {
  const { t } = useTranslation('Ticketing');
  const { reset } = useFormContext<TicketForm>();
  const { setSaveMode, foundSaveAction, saveActions, formState } =
    useTicketMutationContext();

  return (
    <HStack justifyContent="flex-end">
      {formState === 'error' && (
        <Text as="span" color="red.500">
          {t('global:save_error')}
        </Text>
      )}
      {formState === 'saved' && (
        <Text as="span" color="green.500">
          {t('global:save_success')}
        </Text>
      )}
      <Button
        colorScheme="red"
        variant="outline"
        leftIcon={<FaUndo />}
        onClick={() => {
          reset();
        }}
      >
        {t('Ticketing:save_actions.undo_changes')}
      </Button>
      {foundSaveAction && (
        <ButtonGroup isAttached>
          <Button
            type="submit"
            colorScheme="brand"
            leftIcon={foundSaveAction.Icon}
            isLoading={formState === 'saving'}
          >
            {foundSaveAction.name}
          </Button>
          <Menu>
            <MenuButton
              variant="outline"
              as={IconButton}
              icon={<FaChevronDown />}
              aria-label="open-menu"
            />
            <MenuList>
              {saveActions?.map((action) => (
                <MenuItem
                  icon={action.Icon}
                  key={action.name}
                  onClick={() => {
                    if (setSaveMode) {
                      setSaveMode(action.type);
                    }
                  }}
                >
                  {action.name}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        </ButtonGroup>
      )}
    </HStack>
  );
};
