import { ReactElement, ReactNode, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { useDisplayMode } from '@bq/components/ListingSettings';
import { useChangeEffect } from 'BootQuery/Assets/js/use-change-effect';

import { SaveAction } from '../../SaveActions/types';
import { useSaveActions } from '../../SaveActions/useSaveActions';
import { useSaveButtonMode } from '../../SaveActions/useSaveButtonMode';
import { Ticket, TicketForm } from '../../types';
import { TicketMutateProps } from '../Layout/types';
import { TicketInfoSyncer } from './TicketInfoSyncer';
import { TicketMutationProvider } from './TicketMutationContext';
import { makeDefaults } from './utils';

export interface TicketingFormWrapperProps {
  defaultValues?: Partial<Ticket>;
  children?: ReactNode;
  formId?: string;
  saveID?: string;
  data: TicketMutateProps;
  onSubmit: (data: Partial<TicketForm>, action?: () => void) => void;
}

export const TicketingFormWrapper = ({
  defaultValues = {},
  children,
  formId,
  saveID,
  onSubmit,
  data: props,
}: TicketingFormWrapperProps): ReactElement => {
  const defaults = useMemo(() => makeDefaults(defaultValues), [defaultValues]);
  const formMethods = useForm<TicketForm>({
    mode: 'onBlur',
    defaultValues: defaults,
  });
  const {
    formState: { isDirty },
  } = formMethods;

  useChangeEffect(saveID, () => {
    formMethods.reset(defaults);
  }, [defaults]);

  const [displayMode] = useDisplayMode('Ticketing.TicketList');

  const [saveMode, setSaveMode] = useSaveButtonMode(
    'Ticketing.TicketList',
    displayMode
  );

  const saveActions: SaveAction[] = useSaveActions();
  const foundAction = useMemo(() => {
    return saveActions.find((action) => action.type === saveMode);
  }, [saveActions, saveMode]);

  return (
    <TicketMutationProvider
      {...props}
      saveActions={saveActions}
      saveMode={saveMode}
      setSaveMode={setSaveMode}
      foundSaveAction={foundAction}
    >
      <FormProvider {...formMethods}>
        <form
          data-form-dirty={isDirty}
          data-ignore-form-save
          id={formId}
          style={{ display: 'flex', flex: '1 1 auto', height: '100%' }}
          onSubmit={formMethods.handleSubmit((data) => {
            onSubmit(data, foundAction?.callback);
          })}
        >
          <TicketInfoSyncer />
          {children}
        </form>
      </FormProvider>
    </TicketMutationProvider>
  );
};
