import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import { useTabControl } from '@bq/components/TabControl';
import { UseTabControlReturn } from '@bq/components/TabControl/types';
import { useChangeEffect } from 'BootQuery/Assets/js/use-change-effect';

import { useTicketingEvent } from '../js/ticketing-event-bus';

interface Props {
  tabs: string[];
  typeDefaultTab?: string;
}

export function useTicketingTabControl({
  tabs,
  typeDefaultTab,
}: Props): UseTabControlReturn<string> {
  const hash = useLocation().hash.replace('#', '');
  const defaultHashTab = tabs.includes(hash) ? hash : null;
  const [tabManual, tabIndex, setTab] = useTabControl({
    tabs,
    defaultTab: defaultHashTab || typeDefaultTab || tabs[0],
  });

  useChangeEffect(tabManual, () => {
    const { pathname, search } = window.location;
    const newUrl = `${pathname}${search}#${tabManual}`;

    // Using useNavigate causes a big re-render that loses most form and event data
    // so manually update URL until we have time to debug that
    window.history.replaceState(null, '', newUrl);
  }, []);

  // Allow firing a global tab/switch event for automatically switching to
  // another tab
  useTicketingEvent(
    'tab/switch',
    useCallback(
      (newTab) => {
        setTab(newTab);
      },
      [setTab]
    )
  );

  return [tabManual, tabIndex, setTab];
}
