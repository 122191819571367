import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FaArrowCircleLeft,
  FaArrowLeft,
  FaArrowRight,
  FaSave,
} from 'react-icons/fa';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useSaveButtonLinks } from '../use-save-button-links';
import { SaveAction } from './types';

export const useSaveActions = (): SaveAction[] => {
  const { t } = useTranslation('Ticketing');
  const { overviewSlug } = useParams();
  const { nextID, previousID } = useSaveButtonLinks();
  const query = useLocation().search;

  const navigate = useNavigate();

  const saveAndNext = useCallback(() => {
    if (nextID) {
      if (overviewSlug) {
        navigate(`/ticketing/list/${overviewSlug}/${nextID}${query}`);
      } else {
        navigate(`/ticketing/edit/${nextID}${query}`);
      }
    }
  }, [navigate, nextID, overviewSlug, query]);

  const saveAndPrevious = useCallback(() => {
    if (previousID) {
      if (overviewSlug) {
        navigate(`/ticketing/list/${overviewSlug}/${previousID}${query}`);
      } else {
        navigate(`/ticketing/edit/${previousID}${query}`);
      }
    }
  }, [previousID, overviewSlug, navigate, query]);

  const saveAndGoBack = useCallback(() => {
    if (overviewSlug) {
      navigate(`/ticketing/list/${overviewSlug}${query}`);
    } else {
      navigate(`/ticketing/list${query}`);
    }
  }, [navigate, overviewSlug, query]);

  const allActions: SaveAction[] = useMemo(() => {
    return [
      {
        name: t('Ticketing:save_actions.save_and_stay'),
        callback: () => {
          /** Empty callback */
        },
        type: 'save',
        Icon: <FaSave />,
      },
      {
        name: t('Ticketing:save_actions.save_and_return'),
        callback: saveAndGoBack,
        Icon: <FaArrowCircleLeft />,
        type: 'saveAndBack',
      },
      {
        name: t('Ticketing:save_actions.save_and_previous'),
        callback: saveAndPrevious,
        type: 'saveAndPrevious',
        Icon: <FaArrowLeft />,
      },

      {
        name: t('Ticketing:save_actions.save_and_next'),
        callback: saveAndNext,
        Icon: <FaArrowRight />,
        type: 'saveAndNext',
      },
    ];
  }, [saveAndGoBack, saveAndNext, saveAndPrevious, t]);

  return allActions;
};
