import { FC, useEffect, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { getTicketing } from '../../ticketing';
import { useTicketingContext } from '../../TicketingContext';
import { TicketForm } from '../../types';

/**
 * Since some things are for now still handled through the legacy ticketing "module",
 * we need to somehow provide it with current ticket info.
 *
 * This is a renderless component that sets the info about the current ticket
 * on the module object itself. It watches for changes continously.
 *
 * This is currently used for formatting email subjects,
 * selecting default accounts, catching "mailto:" links etc.
 *
 */
export const TicketInfoSyncer: FC = () => {
  const { groups } = useTicketingContext();
  const { control } = useFormContext<TicketForm>();
  const [selectedGroup, ticketID] = useWatch({
    control,
    name: ['group', 'ID'],
  });

  const groupID = selectedGroup?.ID ?? null;
  const group = useMemo(() => {
    return groups.find((g) => g.ID === groupID);
  }, [groups, groupID]);

  useEffect(() => {
    if (ticketID) {
      getTicketing().ticketInfo = {
        ID: ticketID,
        group: group ?? null,
      };
    } else {
      getTicketing().ticketInfo = null;
    }

    return () => {
      getTicketing().ticketInfo = null;
    };
  }, [group, ticketID]);

  return <></>;
};
