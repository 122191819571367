import { createContext, ReactNode, useContext } from 'react';

import { TicketCallCatcher } from './TicketCallCatcher';
import { Ticket } from './types';

const TicketContext = createContext<Ticket | null>(null);

interface Props {
  children: ReactNode;
  ticket: Ticket | null;
}
export const TicketProvider = ({ ticket, children }: Props) => {
  return (
    <TicketCallCatcher>
      <TicketContext.Provider value={ticket}>{children}</TicketContext.Provider>
    </TicketCallCatcher>
  );
};
export const useTicketContext = () => {
  const ctxRet = useContext(TicketContext);

  return ctxRet;
};
