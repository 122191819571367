import { ReactElement, useCallback, useMemo } from 'react';
import { ModalBody, ModalCloseButton } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';

import { useFormState } from '@bq/components/form-state';
import { loadValues, useFormDefinition } from '@bq/components/FormEditor';
import { LoadingPage } from '@bq/components/LoadingPage';

import { getTicket } from '../Api/ticket/ticket';
import { useTicketingContext } from '../TicketingContext';
import { handleEditSubmit } from '../TicketingForm/handle-edit-submit';
import { TicketBreadcrumbsHeader } from '../TicketingForm/Layout/TicketBreadcrumbsHeader';
import { TicketFormBody } from '../TicketingForm/Layout/TicketFormBody';
import { TicketFormContextWrapper } from '../TicketingForm/Layout/TicketFormContextWrapper';
import { TicketFormFooter } from '../TicketingForm/Layout/TicketFormFooter';
import { getDependencies } from '../TicketTable/Dependencies';
import { TicketForm, TicketType } from '../types';
import { TicketModalEditData } from './types';

export const TicketModalEditContent = ({
  ticketID,
  submitCallback,
  apiCallParams,
  parentID,
}: TicketModalEditData): ReactElement => {
  const { data: rawTicket, isFetching } = useQuery({
    queryKey: ['loadTicket', ticketID],
    queryFn: async () => {
      if (!ticketID) {
        return undefined;
      }
      const { data } = await getTicket(ticketID);

      return data;
    },
    enabled: ticketID !== null,
  });
  const { types } = useTicketingContext();
  const ticketType = useMemo(() => {
    return types.find(
      (type) => type.slug === rawTicket?.ticketType.slug
    ) as TicketType;
  }, [rawTicket?.ticketType.slug, types]);

  if (rawTicket && !ticketType) {
    throw Error('Unkown ticket type');
  }
  const { data: deps, isFetching: isFetchingDeps } = useQuery({
    queryKey: ['Ticketing.LoadDefaultDependencies', ticketID],
    queryFn: () => {
      if (!ticketID) {
        return undefined;
      }

      return getDependencies(ticketID);
    },
    enabled: ticketID !== null,
  });

  const customFields = useFormDefinition(
    `TicketTypes.${rawTicket?.ticketType.ID}`,
    'use',
    { enabled: !!rawTicket?.ticketType.ID }
  );
  const ticket = useMemo(() => {
    if (customFields && rawTicket) {
      return {
        ...rawTicket,
        data: loadValues(customFields, rawTicket.data),
        subtickets: deps,
        ticketType,
      };
    }

    return undefined;
  }, [customFields, rawTicket, deps, ticketType]);
  const [formState, setFormState] = useFormState();

  const handleSubmit = useCallback(
    (data: Partial<TicketForm>) => {
      setFormState('saving');
      handleEditSubmit(data, apiCallParams, parentID).then((fromAPI) => {
        setFormState('saved');
        if (submitCallback) {
          submitCallback(fromAPI);
        }
      });
    },
    [apiCallParams, parentID, setFormState, submitCallback]
  );

  if (ticket && ticketID && !isFetching && !isFetchingDeps) {
    return (
      <ModalBody
        m={0}
        p={0}
        maxH="full"
        overflow="hidden"
        display="flex"
        flexDirection="column"
        w="full"
      >
        <TicketFormContextWrapper
          mode="edit"
          ticketID={ticketID}
          formId={`edit-ticket-${ticketID}`}
          defaults={ticket}
          onSubmit={handleSubmit}
          customFields={customFields}
          formState={formState}
          setFormState={setFormState}
        >
          <TicketBreadcrumbsHeader>
            <ModalCloseButton position="static" />
          </TicketBreadcrumbsHeader>
          <TicketFormBody />
          <TicketFormFooter />
        </TicketFormContextWrapper>
      </ModalBody>
    );
  }

  return (
    <ModalBody>
      <LoadingPage />
    </ModalBody>
  );
};
