import { useQuery } from '@tanstack/react-query';

import { getNextTicket, getPreviousTicket } from './adjacent-tickets';
import { useTicketContext } from './TicketContext';
import { useListingSettingsContext } from './TicketingListingSettingsProvider';
import { useTicketId } from './use-ticket-id';

export const useSaveButtonLinks = () => {
  const ticketID = useTicketId();
  const ticket = useTicketContext();
  const listingSettings = useListingSettingsContext();
  const { sort, filters } = listingSettings;
  const { data } = useQuery({
    queryKey: [`prev-next-${ticketID}`, sort, filters, ticket],
    queryFn: async () => {
      let prev = null;
      let next = null;
      if (ticket) {
        prev = await getPreviousTicket(ticket, {
          sort: sort.map((item) => `${item.sortBy}:${item.direction}`),
          filters,
          fields: ['ID'],
        });
        next = await getNextTicket(ticket, {
          sort: sort.map((item) => `${item.sortBy}:${item.direction}`),
          filters,
          fields: ['ID'],
        });
      }

      return { prev, next };
    },
  });

  return { nextID: data?.next?.ID ?? null, previousID: data?.prev?.ID ?? null };
};
