import { TicketType } from '../js/types';
import { TicketGroup } from './types';

export interface TicketingModule {
  getTicketTypes: () => Promise<TicketType[]>;
  getTicketID: () => number | null;
  ticketInfo: {
    ID: number | null;
    group: TicketGroup | null;
  } | null;
}

export function getTicketing(): TicketingModule {
  return window.BootQuery.getModuleInstance('Ticketing') as TicketingModule;
}
