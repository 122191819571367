import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Breadcrumb, Breadcrumbs } from '@bq/components/Breadcrumbs';

import { useTicketingContext } from '../../TicketingContext';

type RouteParams = {
  typeSlug?: string;
};

export const CreateBreadcrumbs = () => {
  const { t } = useTranslation('Ticketing');
  const { typeSlug } = useParams<RouteParams>();
  const { types } = useTicketingContext();
  const foundType = useMemo(
    () => types.find((type) => type.slug === typeSlug),
    [typeSlug, types]
  );

  return (
    <Breadcrumbs>
      <Breadcrumb to="/ticketing/list">
        {t('Ticketing:ticketing_title')}
      </Breadcrumb>
      <Breadcrumb to="/ticketing/create">{t('global:create')}</Breadcrumb>
      {foundType && (
        <Breadcrumb to={`/ticketing/create/${typeSlug}`}>
          {foundType.name}
        </Breadcrumb>
      )}
    </Breadcrumbs>
  );
};
