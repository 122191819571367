import { ReactElement, useCallback } from 'react';
import { ModalBody, ModalCloseButton } from '@chakra-ui/react';

import { useFormState } from '@bq/components/form-state';
import { useFormDefinition } from '@bq/components/FormEditor';
import { LoadingPage } from '@bq/components/LoadingPage';
import { useTicketDefaults } from '@bq/components/use-ticket-defaults';

import { useTicketingContext } from '../TicketingContext';
import { handleCreateSubmit } from '../TicketingForm/handle-create-submit';
import { TicketBreadcrumbsHeader } from '../TicketingForm/Layout/TicketBreadcrumbsHeader';
import { TicketFormBody } from '../TicketingForm/Layout/TicketFormBody';
import { TicketFormContextWrapper } from '../TicketingForm/Layout/TicketFormContextWrapper';
import { TicketFormFooter } from '../TicketingForm/Layout/TicketFormFooter';
import { TicketForm } from '../types';
import { TicketModalCreateData } from './types';
import { useTicketModal } from './use-ticket-modal';

export const TicketModalCreateContent = ({
  typeSlug,
  createParams,
  submitCallback,
  apiCallParams,
  parentID,
  ID,
}: TicketModalCreateData): ReactElement => {
  const { types, priorities } = useTicketingContext();
  const { closeModal } = useTicketModal();
  const ticketType = types.find((type) => type.slug === typeSlug);

  const customFields = useFormDefinition(
    `TicketTypes.${ticketType?.ID}`,
    'use',
    {
      enabled: !!ticketType,
    }
  );
  const defaults = useTicketDefaults({
    ticketType,
    createParams,
    priorities,
  });
  const [formState, setFormState] = useFormState();

  const handleSubmit = useCallback(
    (data: Partial<TicketForm>) => {
      setFormState('saving');
      handleCreateSubmit(data, apiCallParams, parentID).then((fromAPI) => {
        setFormState('saved');
        closeModal(ID);
        if (submitCallback) {
          submitCallback(fromAPI);
        }
      });
    },
    [ID, apiCallParams, closeModal, parentID, setFormState, submitCallback]
  );

  if (ticketType && !defaults.isLoading) {
    return (
      <ModalBody
        m={0}
        p={0}
        maxH="full"
        overflow="hidden"
        display="flex"
        w="full"
      >
        <TicketFormContextWrapper
          mode="create"
          customFields={customFields}
          formState={formState}
          setFormState={setFormState}
          defaults={defaults}
          onSubmit={handleSubmit}
        >
          <TicketBreadcrumbsHeader>
            <ModalCloseButton position="static" />
          </TicketBreadcrumbsHeader>
          <TicketFormBody />
          <TicketFormFooter />
        </TicketFormContextWrapper>
      </ModalBody>
    );
  }

  return (
    <ModalBody>
      <LoadingPage />
    </ModalBody>
  );
};
