import { LayoutFooter } from '@bq/components/Layout';

import { SimpleSaveAction } from '../../SaveActions/SimpleSaveAction';
import { TicketingSaveActions } from '../../SaveActions/TicketingSaveActions';
import { useTicketMutationContext } from '../FormWrapper/TicketMutationContext';

export const TicketFormFooter = () => {
  const { mode } = useTicketMutationContext();

  return (
    <LayoutFooter flex="0 1 auto">
      {mode === 'edit' ? <TicketingSaveActions /> : <SimpleSaveAction />}
    </LayoutFooter>
  );
};
