import { useUserSetting } from 'BootQuery/Assets/js/user-settings';

import { SaveActionType } from './types';

export function useSaveButtonMode(
  moduleName: string,
  viewName = 'table',
  defaultVal: SaveActionType = 'save'
) {
  return useUserSetting(
    `${moduleName}.${viewName}.saveButtonMode`,
    defaultVal,
    false
  );
}
