import { useContext } from 'react';

import { TicketModalContext } from './TicketModalContext';
import { ITicketModalContext } from './types';

export function useTicketModal(): ITicketModalContext {
  const ctx = useContext(TicketModalContext);
  if (!ctx) {
    throw new Error('Missing TicketModalContext');
  }

  return ctx;
}
