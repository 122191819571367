import { PropsWithChildren, ReactElement, useCallback } from 'react';

import { awaitCall } from 'app/Modules/Telephony/Assets/components/Dialer/store/dialer';
import { CallResponse } from 'app/Modules/Telephony/Assets/components/Dialer/types';
import { DialInterceptor } from 'app/Modules/Telephony/Assets/components/DialInterceptor';

import { ticketingEventBus } from '../js/ticketing-event-bus';
import { useEventChanges } from './use-event-changes';

export const TicketCallCatcher = ({
  children,
}: PropsWithChildren): ReactElement => {
  const { addTicketEvent } = useEventChanges(() => {
    /* Not used */
  });

  const onDial = useCallback(
    (ev: CallResponse) => {
      awaitCall(ev).then((call) => {
        addTicketEvent({ type: 'call', callID: call.ID });
        ticketingEventBus.emit('tab/switch', 'events');
        ticketingEventBus.emit('call/new', call);
      });
    },
    [addTicketEvent]
  );

  return <DialInterceptor onDial={onDial}>{children}</DialInterceptor>;
};
