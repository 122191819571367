import { MouseEventHandler, useCallback } from 'react';

import { GetListingParams } from 'BootQuery/Assets/js/globalTypes';

import { CallbackTicket } from '../TicketingForm/Dependencies/types';
import { useTicketModal } from '../TicketModal';
import { TicketType } from '../types';

interface Props {
  type: TicketType;
  modalOnly?: boolean;
  submitCallback?: (data: CallbackTicket) => void;
  params?: GetListingParams;
  parentID?: number;
}

export const useCreateHandleClick = ({
  type,
  modalOnly,
  submitCallback,
  params,
  parentID,
}: Props): MouseEventHandler => {
  const { createTicket } = useTicketModal();

  return useCallback(
    (ev) => {
      if (ev.shiftKey || modalOnly) {
        ev.preventDefault();
        createTicket({
          typeSlug: type.slug,
          submitCallback,
          apiCallParams: params,
          parentID,
        });
      }
    },
    [createTicket, modalOnly, params, parentID, submitCallback, type.slug]
  );
};
