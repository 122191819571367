import { ReactElement, useId, useMemo } from 'react';
import { Tabs } from '@chakra-ui/react';

import { TabControlProvider } from '@bq/components/TabControl/TabControlContext';
import { useIsMobile } from '@bq/components/use-is-mobile';

import { useTicketingTabControl } from '../../use-ticketing-tab-control';
import { TicketingFormWrapper } from '../FormWrapper/FormWrapper';
import { TicketFormContextWrapperProps } from './types';

export const TicketFormContextWrapper = ({
  formId,
  customFields,
  formState,
  setFormState,
  defaults,
  onSubmit,
  saveID,
  children,
  ...modeProps
}: TicketFormContextWrapperProps): ReactElement => {
  const fallbackId = useId();
  const id = formId ?? fallbackId;
  const isMobile = useIsMobile();

  const tabs = useMemo(() => {
    return isMobile
      ? ['about', 'details', 'events', 'dependencies', 'works']
      : ['details', 'events', 'dependencies', 'works'];
  }, [isMobile]);

  const [tabManual, tabIndex, setTab] = useTicketingTabControl({
    tabs,
    typeDefaultTab: isMobile ? 'about' : defaults.ticketType?.defaultTab,
  });

  return (
    <TicketingFormWrapper
      formId={id}
      defaultValues={defaults}
      saveID={saveID}
      onSubmit={onSubmit}
      data={{
        formId,
        customFields,
        formState,
        setFormState,
        defaults,
        onSubmit,
        saveID,
        ...modeProps,
      }}
    >
      <TabControlProvider tab={tabManual} tabIndex={tabIndex} setTab={setTab}>
        <Tabs
          display="flex"
          flexDirection="column"
          h="full"
          w="full"
          index={tabIndex}
          onChange={setTab}
          colorScheme="brand"
          orientation={isMobile ? 'vertical' : 'horizontal'}
          size={{ base: 'lg', lg: 'md' }}
        >
          {children}
        </Tabs>
      </TabControlProvider>
    </TicketingFormWrapper>
  );
};
