import { ReactElement, useContext } from 'react';
import {
  Modal,
  ModalContent,
  ModalOverlay,
  useColorModeValue,
} from '@chakra-ui/react';

import { ConditionalWrapper } from '@bq/components/ConditionalWrapper';

import { TicketingContext, TicketingProvider } from '../TicketingContext';
import { TicketListingSettingsProvider } from '../TicketingListingSettingsProvider';
import { TicketModalCreateContent } from './TicketModalCreateContent';
import { TicketModalEditContent } from './TicketModalEditContent';
import { TicketModalSelectType } from './TicketModalSelectType';
import { TicketModalProps } from './types';

export const TicketModal = (props: TicketModalProps): ReactElement => {
  const {
    onClose,
    state: { ID },
  } = props;
  const hasContext = useContext(TicketingContext) !== null;
  const bg = useColorModeValue('brand.lightGray', 'brand.darkGray');
  const color = useColorModeValue('#3C485C', '#F5F5F5');

  return (
    <Modal
      size="6xl"
      scrollBehavior="inside"
      isOpen={true}
      onClose={() => {
        onClose(ID);
      }}
    >
      <ModalOverlay />
      <ModalContent bg={bg} color={color} w="full" h="full">
        <ConditionalWrapper
          shouldWrap={!hasContext}
          render={(children) => (
            <TicketingProvider>{children}</TicketingProvider>
          )}
        >
          <TicketListingSettingsProvider>
            <TicketModalContent {...props} />
          </TicketListingSettingsProvider>
        </ConditionalWrapper>
      </ModalContent>
    </Modal>
  );
};

const TicketModalContent = (props: TicketModalProps): ReactElement => {
  if (props.state?.mode === 'create') {
    return <TicketModalCreateContent {...props.state} />;
  }
  if (props.state?.mode === 'edit') {
    return <TicketModalEditContent {...props.state} />;
  }

  if (props.state?.mode === 'typeSelect') {
    return <TicketModalSelectType {...props.state} />;
  }

  return <> </>;
};
