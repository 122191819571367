import { Api } from '../api';
import { Translation, TranslationCollection, TranslationLoader } from './types';

type LanguageTranslations = Record<string, Translation>;

async function loadTranslation(
  language: string
): Promise<TranslationCollection[]> {
  const { data: translations } = await Api.get<LanguageTranslations>(
    `/api/translations/weblate/${language}`
  );

  return Object.entries(translations).map(([namespace, translation]) => ({
    locale: language,
    value: translation,
    module: namespace,
  }));
}

// Nothing to export here, will be handled by our backend
export const translations: TranslationLoader = async () => {
  const byLanguage = await Promise.all([
    loadTranslation('hr'),
    loadTranslation('en'),
    loadTranslation('de'),
  ]);

  return byLanguage.flat();
};
export const rawLocale = true;
