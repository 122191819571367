import { Box, HStack, ModalCloseButton, Tab, TabList } from '@chakra-ui/react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  TicketCreateFollow,
  TicketEditFollow,
  TicketForm,
  TicketWatchers,
  useTicketWatchers,
} from 'app/Modules/Ticketing/Assets';

import { TicketModalContentProps } from './types';

export const TicketModalHeader = (props: TicketModalContentProps) => {
  const ticketID = props.mode === 'edit' ? props.ID : null;
  const { t } = useTranslation('Ticketing');
  const watchers = useTicketWatchers(ticketID);

  const type = useWatch<TicketForm, 'ticketType'>({ name: 'ticketType' });

  return (
    <HStack>
      <TabList border="none" h={{ base: 'auto', lg: '4rem' }} flex="1 1 auto">
        <Tab>{t('Ticketing:details')}</Tab>
        <Tab>{t('Ticketing:events')}</Tab>
        {type.canHaveDependencies && <Tab>{t('Ticketing:subtickets')}</Tab>}
      </TabList>
      {ticketID && (
        <Box fontSize="md">
          {`${t('Ticketing:ticket')} ${t('Ticketing:form.id')}: #${ticketID}`}
        </Box>
      )}
      <HStack py={{ base: 2, lg: 0 }} flex="0 1 auto" px={2} spacing={0}>
        {watchers.length > 0 && <TicketWatchers watchers={watchers} />}
        {props.mode === 'edit' && ticketID ? (
          <>
            <TicketEditFollow
              watch={props.data?.isWatchedByCurrentUser ?? false}
              ticketID={ticketID}
            />
          </>
        ) : (
          <TicketCreateFollow />
        )}
        <ModalCloseButton position="static" />
      </HStack>
    </HStack>
  );
};
