import { useColorModeValue } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Breadcrumb, Breadcrumbs } from '@bq/components/Breadcrumbs';

import { useSelectedOverview } from '../../use-selected-overview';

type RouteParams = {
  ticketID: string;
};

export const EditBreadcrumbs = () => {
  const { t } = useTranslation('Ticketing');
  const { ticketID } = useParams<RouteParams>();
  const selectedOverview = useSelectedOverview();
  const color = useColorModeValue('blackAlpha.700', 'whiteAlpha.700');

  const currentUrl = selectedOverview
    ? `/ticketing/list/${selectedOverview.slug}/${ticketID}`
    : `/ticketing/edit/${ticketID}`;

  return (
    <Breadcrumbs color={color}>
      <Breadcrumb to="/ticketing/list">
        {t('Ticketing:ticketing_title')}
      </Breadcrumb>
      {selectedOverview && (
        <Breadcrumb to={`/ticketing/list/${selectedOverview.slug}`}>
          {selectedOverview.title}
        </Breadcrumb>
      )}
      <Breadcrumb to={currentUrl}>#{ticketID}</Breadcrumb>
    </Breadcrumbs>
  );
};
