import { Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { GetListingParams } from 'BootQuery/Assets/js/globalTypes';

import { CallbackTicket } from '../TicketingForm/Dependencies/types';
import { TicketType } from '../types';
import { createTicketLink } from '../utils';
import { useCreateHandleClick } from './use-create-handle-click';

interface Props {
  modalOnly?: boolean;
  submitCallback?: (data: CallbackTicket) => void;
  params?: GetListingParams;
  parentID?: number;
  overviewSlug?: string;
  type: TicketType;
}

export const AddSingleTypeTicket = ({
  overviewSlug,
  type,
  params,
  parentID,
  submitCallback,
  modalOnly,
}: Props) => {
  const { t } = useTranslation();

  const ticketLink = createTicketLink(overviewSlug, type);

  const handleClick = useCreateHandleClick({
    modalOnly,
    submitCallback,
    type,
    params,
    parentID,
  });

  return (
    <Button
      _hover={{
        textDecoration: 'none',
        backgroundColor: 'green.300',
        textColor: 'blackAlpha.800',
      }}
      as={Link}
      colorScheme="green"
      to={ticketLink}
      onClick={handleClick}
    >
      {`${t('global:create')} "${type.name}"`}
    </Button>
  );
};
