import { useMemo } from 'react';
import { Box, ButtonGroup } from '@chakra-ui/react';
import { useController, useFormContext } from 'react-hook-form';

import { ListHeading } from '@bq/components/ListHeading';
import {
  useHeadingProps,
  useListingSettings,
} from '@bq/components/ListingSettings';
import { LoadingPage } from '@bq/components/LoadingPage';
import { WithChange } from '@bq/components/SettingsCRUD';
import { Table, TableSelectionProvider } from '@bq/components/Table';
import { useDebouncedQuery } from 'BootQuery/Assets/js/use-debounced-query';
import { useLoadUserSettings } from 'BootQuery/Assets/js/user-settings';

import { addDependencies } from '../../Api/dependencies/addDependencies';
import { getTicketsByID } from '../../Api/ticket/getTicketsById';
import { ticketFilterTypes } from '../../filter-types';
import { useTicketingContext } from '../../TicketingContext';
import { AddNewTicket } from '../../TicketTable/AddNewTicket';
import { ticketColumns } from '../../TicketTable/Columns/columns';
import { defaultVisibleColumns } from '../../TicketTable/Columns/defaults';
import {
  DependenciesProvider,
  getDependencies,
} from '../../TicketTable/Dependencies';
import { TicketRow } from '../../TicketTable/types';
import { expandDependencies } from '../../TicketTable/use-tickets-with-dependencies';
import { Ticket, TicketForm } from '../../types';
import { AddOtherTicketAsDep } from './AddOtherTicketAsDep';
import { ticketMutationEvent } from './dependencies-events';
import { TicketDependencyActions } from './TicketDependencyActions';
import { useTicketTreeMutation } from './use-ticket-tree-mutation';

interface Props {
  ticketID: number;
}

export const DependenciesTable = ({ ticketID }: Props) => {
  const { allCustomFields } = useTicketingContext();
  const settingsLoaded = useLoadUserSettings('Ticketing.DependencyTable', {});

  const listingSettings = useListingSettings<WithChange<TicketRow>>({
    listingName: 'Ticketing.DependencyTable',
    viewName: 'table',
    filterTypes: ticketFilterTypes,
    columns: ticketColumns(),
    storage: 'state',
    defaults: {
      visibleColumns: defaultVisibleColumns,
      sort: [{ direction: 'desc', sortBy: 'ID' }],
    },
    customFields: allCustomFields ?? [],
  });

  const { page, columnsToShow, filters, sort, setSort } = listingSettings;

  const headingProps = useHeadingProps({
    listingProps: listingSettings,
    count: null,
    noPagination: true,
  });

  const { control } = useFormContext<TicketForm>();

  const {
    field: { value: subtickets, onChange },
  } = useController<TicketForm, 'subtickets'>({ name: 'subtickets', control });

  const queryKey = ['Ticketing.Dependencies', ticketID, filters];

  useTicketTreeMutation({
    form: { defaults: subtickets, onChange },
    ticketID,
    query: { queryKey },
  });

  /** At load this query is equal to the one used to init deps at ticket load */
  /** So in ideal case we just read fetched data */
  /** Changing filters changes the query and we get other values */
  /** Original full values are still kept at form level */
  const { data } = useDebouncedQuery({
    queryKey,
    queryFn: async () => {
      return getDependencies(ticketID, {
        filters,
      });
    },
  });

  /** We don't want some tickets to be shown when we add existings deps */
  /** Those are those that already are added and parent */
  const excludedTickets = useMemo(() => {
    return [...(data?.map((item) => item.ID) ?? []), ticketID];
  }, [data, ticketID]);

  /** This function is used to convert tree structure into a flat array */
  const fullTickets = expandDependencies(data ?? [], 0, ticketID);

  if (!settingsLoaded) {
    return <LoadingPage />;
  }

  return (
    <>
      <Box py={4}>
        <ListHeading
          {...headingProps}
          Actions={
            <ButtonGroup>
              <AddNewTicket
                modalOnly={true}
                params={{ fields: ['$full'] }}
                submitCallback={async (data) => {
                  const typecasted = data as unknown as Ticket;
                  await addDependencies(ticketID, [data.ID]);
                  /** We update the query and form */
                  ticketMutationEvent({
                    parentID: ticketID,
                    mutation: { action: 'insert', data: typecasted },
                  });
                }}
              />
              <AddOtherTicketAsDep
                excludedTickets={excludedTickets}
                ticketID={ticketID}
                onSubmit={async (depIds) => {
                  await addDependencies(ticketID, depIds);
                  const tickets = await getTicketsByID(depIds);

                  tickets.data.forEach((ticket) => {
                    ticketMutationEvent({
                      parentID: ticketID,
                      mutation: { action: 'insert', data: ticket },
                    });
                  });
                }}
              />
            </ButtonGroup>
          }
        />
      </Box>
      <DependenciesProvider>
        <TableSelectionProvider<TicketRow> idColumn="ID" page={page}>
          <Table<TicketRow>
            sort={{ value: sort, onChange: setSort }}
            columns={[
              ...columnsToShow,
              {
                key: '',
                tdProps: { p: 0 },
                minWidth: 120,
                width: '120px',
                Cell: ({ row }) => (
                  <TicketDependencyActions
                    ticket={row}
                    actionCallbacks={{
                      onDelete: (data) => {
                        ticketMutationEvent({
                          parentID: ticketID,
                          mutation: { action: 'delete', data },
                        });
                      },
                      onUnlinkDep: (data) => {
                        ticketMutationEvent({
                          parentID: ticketID,
                          mutation: { action: 'unlink', data },
                        });
                      },
                      onUpdate: (data) => {
                        ticketMutationEvent({
                          parentID: ticketID,
                          mutation: { action: 'update', data },
                        });
                      },
                    }}
                  />
                ),
              },
            ]}
            rows={fullTickets}
          />
        </TableSelectionProvider>
      </DependenciesProvider>
    </>
  );
};
