import { get, has } from 'lodash-es';

import { FilterExpression } from '@bq/components/FilterBar';
import { SortDirection } from '@bq/components/Table';

import { Ticket } from '../types';
import { ParsedSort, PrevNext } from './types';

/**
 * Generates filter conditions for getting the previous/next ticket for given sort order.
 * You can look at the tests for examples of input/output
 */
export function conditionsFromSort(
  ticket: Ticket,
  sorts: string[],
  siblingDir: PrevNext
): FilterExpression {
  const conditions: FilterExpression[] = [];

  for (let i = sorts.length; i > 0; i--) {
    const sortsToUse = sorts.slice(0, i).map(parseSort);
    const lastSort = sortsToUse.slice(-1)[0];
    const otherSorts = sortsToUse.slice(0, -1);

    const equalityConds = otherSorts.map(({ column }) => {
      return [column, 'eq', getColumnValue(ticket, column)];
    });

    const lastCond = [
      lastSort.column,
      sortComp(siblingDir, lastSort.direction),
      getColumnValue(ticket, lastSort.column),
    ];

    conditions.push({ $and: [...equalityConds, lastCond] });
  }

  return { $or: conditions };
}

function getColumnValue(ticket: Ticket, column: string): unknown {
  if (!has(ticket, column)) {
    // Ignore missing custom data fields. Field could've been added after the
    // ticket was created
    if (column.startsWith('data.')) {
      return null;
    }

    console.error('Unknown ticket column: ', column, ticket);
    throw new Error('Unknown ticket column');
  }

  return get(ticket, column);
}

/**
 * Takes the sort order and generates a reverse order
 * For example [title:asc, ID:desc] would become [title:desc, ID:asc]
 */
export function reverseSorts(sorts: string[]): string[] {
  return sorts.map(parseSort).map(({ column, direction }) => {
    const revDir = direction === 'asc' ? 'desc' : 'asc';

    return `${column}:${revDir}`;
  });
}

/**
 * Get the sort condition for previous or next value, based on column sort direction
 *
 * Simple example values for expected results:
 * ```
 * sort: ID:asc
 * values: 112, >113, 114
 * current: 113
 * prev: 112, cond: 'lt',
 * next: 114: cond: 'gt',
 *
 * sort: ID:desc
 * values: 114, >113, 112
 * current: 113,
 * prev: 114, cond: 'gt',
 * next: 112, cond: 'lt',
 * ```
 *
 */
export function sortComp(
  siblingDir: PrevNext,
  sortDir: SortDirection
): 'lt' | 'gt' {
  if (sortDir === 'asc') {
    return siblingDir === 'next' ? 'gt' : 'lt';
  }

  // sortDir: desc
  return siblingDir === 'next' ? 'lt' : 'gt';
}

/**
 * Takes a sort value of format {column}:{sortDir} or {column}
 * and parses it into a struct
 *
 * If sort order is not explicitly specified, ascending is the default.
 * This is consistent with the backend behaviour
 *
 */
export function parseSort(sort: string): ParsedSort {
  const [column, ...otherParts] = sort.split(':');

  // If direction not specified, default is ascending
  const direction = otherParts[0] ?? 'asc';
  if (direction !== 'asc' && direction !== 'desc') {
    console.error('Unable to parse sort direction: ', direction);
    throw new Error('Unable to parse sort direction');
  }

  return {
    column,
    direction,
  };
}
